// Used in sentry.*.config.ts

export const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

/** @type {(string|RegExp)[]} */
export const ignoreErrors = [
  // Ignore http://bit.ly/2hk42Ap, resulting from what appears to be a Chrome
  // bug http://bit.ly/2gVbncS
  /__gCrWeb\.autofill\.extractForms/,

  // Ignore http://bit.ly/2hk71Zv, resulting from some really strange issue in
  // Safari 9. Experienced by Mozilla team, too http://bit.ly/2hk9vHq
  /docs-homescreen-gb-container/,

  // Ignore http://bit.ly/2mVGcgX. It's a weird React error. Google says it
  // _might_ be a logging error? http://bit.ly/2lEB3ZW
  /e\.getHostNode/,

  // Ignore http://bit.ly/2lyZSao, resulting from some Chrome plugin presumably.
  /ahk_main/,

  // Some weird Firefox errors that seem to happen only on Firefox for iOS.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1394296
  /__firefox__\./,

  // Next.js emits an error when multiple route transitions are queued at once,
  // presumably to cancel the code for rendering all but the last page. This is
  // not a real error that needs to be reported to Sentry.
  /Cancel rendering route/,
];
