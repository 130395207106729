export function censorSensitiveInformation(data: unknown): void {
  // Ensure that the data is an object before continuing
  if (!isObject(data)) {
    return;
  }
  internalCensorSensitiveInformation(new Set(), data);
}

function internalCensorSensitiveInformation(
  seen: Set<Record<string, unknown>>,
  data: Record<string, unknown>,
): void {
  // Don't process the same item twice. Avoids infinite recursion in the case of
  // cyclic arrays/objects.
  if (seen.has(data)) {
    return;
  }
  seen.add(data);
  // FYI: Arrays are objects and can be iterated the same way...
  //
  //    Object.entries(["A", "B"])
  //    //=> [["0", "A"], ["1", "B"]]
  //
  for (const [key, val] of Object.entries(data)) {
    if (isSensitiveKey(key)) {
      // Remove sensitive keys
      delete data[key];
    } else if (isObject(val)) {
      // Recurse into nested objects
      internalCensorSensitiveInformation(seen, val);
    }
  }
}

// A better version of `typeof data === "object"` that accounts for null
function isObject(data: unknown): data is Record<string, unknown> {
  return typeof data === "object" && data !== null;
}

// Maybe this should be more aggressive in determining sensitive keys? When it
// was originally written it only caught "token" at the end of a string, not
// anywhere in it. Maybe the other string checks should actually be
// case-insensitive regexp checks? Feel free to update this later...
function isSensitiveKey(key: string): boolean {
  return (
    key === "password" ||
    key === "secret" ||
    key === "credentials" ||
    /token/i.test(key)
  );
}
