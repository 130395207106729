import Bowser from "bowser";

export const isValidBrowser = (userAgent: string) => {
  const browser = Bowser.getParser(userAgent);

  return browser.satisfies({
    chrome: ">=108",
    edge: ">=108",
    firefox: ">=109",
    safari: ">=16",
  });
};
