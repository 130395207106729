import { useRouter } from "next/router";
import { useEffect } from "react";
import { getReferrer } from "src/utils/getReferrer";
import { isBrowser } from "src/utils/isBrowser";
import { emitPageViewEvent } from "./events";

export function useTrackRouting(): void {
  const router = useRouter();
  const path = `${router.basePath}${router.asPath}`;
  const referer = getReferrer();
  const shouldEmit =
    router.isReady &&
    !(path.includes("[") && path.includes("]")) &&
    !path.includes("__internal") &&
    isBrowser;

  let utm_source: string | undefined;
  let utm_medium: string | undefined;
  let utm_campaign: string | undefined;

  // For PSE tracking
  if (router.pathname.startsWith("/editor")) {
    utm_source = "partner";
    utm_medium = "embed";
    utm_campaign = "partner_editor";
  }

  useEffect(() => {
    if (shouldEmit) {
      emitPageViewEvent({ referer, utm_source, utm_medium, utm_campaign });
    }
  }, [
    shouldEmit,
    referer,
    utm_source,
    utm_medium,
    utm_campaign,
    router.pathname,
  ]);
}
