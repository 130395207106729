/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Colors, ZapierLogo } from "@zapier/design-system";
import { ReactNode } from "react";

const styleRoot = css`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

const styleHeader = css`
  box-sizing: border-box;
  display: flex;
  min-height: 56px;
  padding: 0 40px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${Colors.neutral300};
`;

const styleMain = css`
  flex: auto;
  display: grid;
  justify-content: stretch;
  align-items: stretch;
`;

export type BasicLayoutProps = {
  children: ReactNode;
};

export function BasicLayout({ children }: BasicLayoutProps): JSX.Element {
  return (
    <div css={styleRoot}>
      <header css={styleHeader}>
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a href="/">
          <ZapierLogo
            height={28}
            // @ts-expect-error Zinnia's types don't allow auto here but it works
            width="auto"
          />
        </a>
      </header>
      <main css={styleMain}>{children}</main>
    </div>
  );
}
