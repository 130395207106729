import { isBrowser } from "./isBrowser";

// We don't actually want the iframe login page to ever be recorded as the
// referrer. We want to know the login page's referrer, since that's going to be
// the 3rd party website itself (e.g. https://partner.example).
export function getReferrer() {
  if (!isBrowser) {
    return "";
  }

  const params = new URLSearchParams(window.location.search);

  return params.get("referer") || params.get("referrer") || document.referrer;
}
