import {
  createAvroEventsHub,
  EventArgs,
  EventNameType,
  RemoveAutoPopulatedEventFields,
} from "@zapier/avro-events";
import { emit } from "@zapier/events-toolkit-browser";
import { Logger } from "src/utils/logger";

const systemName = "partner";

/**
 * A function that takes the appropriate fields for a given Avro event, minus
 * the `system_name` field and the event's name, which are already supplied via
 * `createAvroEventEmitter`.
 *
 * @example
 * ```ts
 * const emitPageViewEvent = createAvroEventEmitter(
 *   "<YOUR_SERVICE_NAME>",
 *   "web_analytics.tracking.page_view.PageViewEvent"
 * );
 *
 * await emitPageViewEvent();
```
 */
export type AvroEventEmitter<T extends EventNameType> = (
  data: Omit<RemoveAutoPopulatedEventFields<EventArgs<T>>, "system_name"> & {
    // Allow overriding referer explicitly, even though it's auto-populated
    referer?: string | null;
    utm_source?: string | string[];
    utm_medium?: string | string[];
    utm_campaign?: string | string[];
  },
) => Promise<void>;

function createAvroEventEmitter<T extends EventNameType>(
  systemName: string,
  eventName: T,
): AvroEventEmitter<T> {
  const eventHub = createAvroEventsHub<T>();
  eventHub.emitFunction = emit;
  return async function emitEvent(data) {
    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test"
    ) {
      // This is not the full data object that would be sent
      // as that is enriched by events-toolkit-browser
      Logger.info(
        `[EVENT] Would have tracked ${eventName} with ${JSON.stringify(data)}`,
      );

      return;
    }

    // I can't seem to convince TypeScript that `Omit`ing "system_name" then
    // adding it back in is a safe operation. It obviously is, though, so I'm
    // forcing this code with an `as any` to make it work.
    const fullData: RemoveAutoPopulatedEventFields<EventArgs<T>> = {
      system_name: systemName,
      ...data,
    } as any;
    await eventHub.emit(fullData as any, eventName);
  };
}

export const emitPageViewEvent = createAvroEventEmitter(
  systemName,
  "web_analytics.tracking.page_view.PageViewEvent",
);

export const emitSignupUserInteractionEvent = createAvroEventEmitter(
  systemName,
  "web_analytics.tracking.user_interaction.SignupUserInteractionEvent",
);
